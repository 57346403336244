.ms-DatePicker-yearPicker{
	min-width: 150px;
	.ms-DatePicker-currentYear{
		outline: none;
	}
	button{
		outline: none;
		transition: background-color 0.2s ease-out;
		font-size: 12px;
    	width: 28px;
    	height: 28px;
    	line-height: 28px;
		display: inline-block;
		text-align: center;
		text-align: center;
		font-size: 12px;
		color: #e5e5e5;
		border-radius: 2px;
		position: relative;
		background-color: transparent;
		border: none;
		padding: 0;
		i{
			display: inline-block;
			-webkit-font-smoothing: antialiased;
			font-style: normal;
			font-weight: normal;
			speak: none;
			font-family: "FabricMDL2Icons-0";
		}

		&:disabled{
			pointer-events: none;
			color: #4c4d4e;
		}

		&:hover{
			background-color: #2e2f2f;
			color: #ffffff;
			cursor: pointer;
		}

		&.active{
			background-color: #363737;
			color: #ffffff;
		}
	}
	.ms-DatePicker-header{
		position: relative;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		height: 28px;
		line-height: 28px;
		width: 100%;
		padding-bottom: 12px;
	}
	.ms-DatePicker-yearRow{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.ms-DatePicker-currentYear{
		font-size: 14px;
		margin: 0;
		height: 28px;
		line-height: 28px;
		display: inline-flex;
		flex-grow: 1;
		padding: 0 5px;
		font-size: 14px;
		font-weight: 400;
		font-weight: 600;
		color: #e5e5e5;
		height: 28px;
		line-height: 28px;
	}
	
	.ms-DatePicker-yearOption{
		font-size: 15px;
		width: 76px;
		height: 38px;
		line-height: 38px;
		margin: 4px;
	}
}

.ms-DatePicker-wrap-year{
	padding: 12px;
}