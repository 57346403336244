main#login{
	//margin-left: 241px;
    //min-height: calc(100vh - 80px);
	//padding: 40px 60px;
	
	display: flex;
	background: linear-gradient(-45deg, #18191a, #1d1f20);
	margin: 0;
	padding: 0;
	min-height:100vh;
	width: 100%;

	.login-form-container{
		//padding: 40px 60px;
		display: flex;
		align-self: center;
		margin: 0 auto 10vh auto;
		
		.logo{
			padding-bottom: 40px;
			width: 300px;
		}
	}
	.login-panel{
		background: linear-gradient(0deg, rgba(38, 38, 38, 0.6), rgba(40, 40, 40, 0.6));
		border-right: 1px solid #38393a;
		height: 100vh;
		
		width: 60vw;
		transition: width 0.5s linear;
		@media (max-width: 1220px) {
			width: 50vw;
		}
		@media (max-width: 900px) {
			width: 36vw;
			> svg{
				animation: visible 1s;
				opacity: 0.015;
			}
		}
		@media (max-width: 700px) {
			width: 20vw;
			> svg{
				animation: invisible 1s; 
				opacity: 0 !important;
			}
		}
		@media (max-width: 520px) {
			width: 10px;
			
		}
		

		> svg{
			max-width: 30%;
			position: fixed;
			bottom: 0;
			opacity: 0.015;
		}
	}

	.ms-TextField-reveal{
		background: #E7F0FE;
		cursor: pointer;

		&:hover{
			background: #b3dbe1;
		}
	}
}
@keyframes visible {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 0.015;
	}
}
@keyframes  invisible {
	from {
	  opacity: 0.015;
	}
	to {
	  opacity: 0;
	}
}

