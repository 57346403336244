.message-bar{
	transition: margin-top 0.2s linear;
	&.visible{
		margin-top: 40px;
	}
	&.hidden{
		margin-top: 0px;
	}
	opacity: 0.8;
	backdrop-filter: blur(8px);
	transform: translateY(-40px);
	user-select: none;
	&:hover{
		filter: saturate(1.3);
	}
	& div.ms-MessageBar-dismissSingleLine:hover{
		filter: saturate(1.3);
		background-color: rgba(0, 0, 0, 0.15);
	}
}

.ms-MessageBar:not(div[class^="ms-MessageBar ms-MessageBar--"]){
    background: #323130;
    color: #d3d3d3;
	
	& .ms-MessageBar-icon i {
		color: #727272;
	}
}
.ms-MessageBar{
	font-size: 13px;
	padding: 3px;
}

.ms-MessageBar-icon{
	margin: 0 0 0 12px;
    align-items: center;
}