.modal-container.dark, main.dark section.manual{
	.ms-DetailsList {
		background: white;
		width: 470px;
		border-radius: 4px;
	}
	
	.table{
		margin: auto;
		width: 100%;
		min-width: unset;
		font-size: 12px;
		border: 1px solid #3b3b3b;
		color: #c5c5c5;
		background-color: #202020;
		border-radius: 4px;
		box-shadow: 0 1px 7px #0e0e0e;
		padding: 0px 2px 4px 2px;
		user-select: none;

		.container{
			cursor: pointer;
			opacity: 1;
			transition: opacity 0.2s;
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.row{
		display: flex;
		line-height: 28px;
		&:not(:last-child){
			border-bottom: 1px solid #3b3b3b;
		}
	
		&:nth-child(odd){
			background-color: #242424;
		}
	
		&:not(.header):hover{
			background-color:#4b4b4a;
			color: #fbfbfb;
		}
		&.header{
			border-top: 1px solid #3b3b3b;
		}
	}
	.cell {
		margin: 4px 8px;
		&.field{
			width: 60%;
			font-weight: 500;
		}
		&.value{
			width: 40%;
		}
		&.headercell{
			color: #f3f2f1;
			font-weight: bold;
			&:first-child{
				width: 60%;
			}
			&:last-child{
				width: 40%;
			}
		}
	}
	
	.labelIcon{
		font-size: 14px;
		margin: auto;
		&>i{
			font-size: 48px;
			width: 100%;
			text-align: center;
			opacity: 0.6;
		}
	}
}
