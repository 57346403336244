* {
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-color);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
}
#root {
  display: flex;
  //color: #D6D6DA;
  //fill: #0D6773;
  //stroke: #064c55;
}

body {
  color: var(--text-color);
}

button {
  transition: background-color 0.2s ease-out;
}

/*
.apexcharts-legend:hover .apexcharts-legend-series:not(:hover){
	opacity: 0.5;
}
*/
.apexcharts-legend .apexcharts-legend-series {
  margin: 0 2px !important;
  filter: brightness(1);
  //opacity: 1;
  background-color: #ffffff00;
  padding: 2px 6px;
  border-radius: 3px;
  transition: background-color 0.3s, filter 0.3s;
  &:hover {
    filter: brightness(1.5);
    background-color: #ffffff24;
  }
}

.apexcharts-datalabels {
  text {
    paint-order: stroke;
    stroke: rgba(0, 0, 0, 0.3);
    stroke-width: 2px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
  }
}
.apexcharts-theme-dark {
  background: rgba(12, 14, 14, 0.75) !important;
  border: 1px solid #151616;
  box-shadow: none;
  backdrop-filter: blur(5px);
  .apexcharts-tooltip-text-value {
    font-weight: 400;
  }
}
.apexcharts-tooltip-series-group {
  padding: 0px 14px !important;
  &:first-child {
    padding-top: 6px !important;
  }
  &:last-child {
    padding-bottom: 6px !important;
  }
}

.chart-container {
  color: rgba(0, 0, 0, 0.8);
  /* margin: -14px -18px 0 -22px;*/
}
.chartjs-container {
  margin: auto -10px;
}
.apexcharts-menu-item.exportPNG,
.apexcharts-menu-item.exportSVG {
  display: none;
}
@media (max-width: 900px) {
  // móvil
  .apexcharts-toolbar {
    //display: none
  }
}
.apexcharts-menu-icon svg {
  fill: rgba(255, 255, 255, 0.5);
}

.legend-items {
  list-style: none;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 0;
  width: 100%;
  font-family: "Segoe UI", "Webly Sleek", "Helvetica Neue", Helvetica, Arial;

  li {
    cursor: default;
    transition: background-color 0.3s, filter 0.3s;
    margin: 0 2px !important;
    padding: 2px 6px;
    border-radius: 3px;
    user-select: none;

    &.hidden {
      opacity: 0.5;
    }

    span {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: attr(data-color);
      display: inline-block;
      margin-right: 9px;
    }
  }

  &.no-hover {
    top: 20px;
    position: relative;
  }

  &:not(.no-hover) {
    li {
      cursor: pointer;

      &:hover {
        filter: brightness(1.5);
        background-color: #ffffff24;
      }
    }
  }
}

.chart-legend.tooltip {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .legend-items {
    display: contents;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: 300;

    li {
      width: 100%;
    }
  }

  .title {
    height: 20px;
    background-color: #ffffff8c;
    color: black;
    position: relative;
    margin: -8px -5px 6px -20px;
    border-radius: 2px 2px 0 0;
    padding: 5px 14px 4px 14px;
    font-size: 13px;
    font-weight: 500;
  }
}

.ms-Toggle {
  transform: translateY(-22px);
  margin-bottom: -22px;
  display: flex;
  opacity: 0.8;
  zoom: 0.9;
  justify-content: flex-end;
  @media (max-width: 900px) {
    // móvil
    justify-content: center !important;
  }

  &:hover {
    opacity: 1;
  }

  .ms-Toggle-background[aria-checked="true"] {
    background: #6e6e6e;
  }

  .ms-Label {
    cursor: pointer;
  }
}

.ms-Layer-content {
  height: 0;
}

.spinner-container {
  &.small {
    width: 60%;
  }
  width: 100%;
  top: 0px;
  height: 80px;
  margin-bottom: -80px;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: center;
}
