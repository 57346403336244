.ms-DetailsList {
  background: white;
  width: 470px;
  border-radius: 4px;
}

.table-style {
  width: 50%;
  min-width: 600px;
  font-size: 12px;
  border: 1px solid #edebe9;
  color: #767676;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.09);
  padding: 0px 2px 4px 2px;
  user-select: none;
}
.row {
  display: flex;
  line-height: 28px;
  &:not(:last-child) {
    border-bottom: 1px solid #edebe9;
  }

  &:nth-child(odd) {
    background: inherit;
  }

  &:not(.header):hover {
    background-color: #f3f2f1;
  }
  &.header {
    border-top: 1px solid #edebe9;
  }
}
.cell {
  margin: 4px 8px;
  &.field {
    width: 60%;
    font-weight: 500;
  }
  &.value {
    width: 40%;
  }

  &.headercell {
    color: #201f1e;
    font-weight: bold;
    &:first-child {
      width: 60%;
    }
    &:last-child {
      width: 40%;
    }
  }
}

.ms-List-cell {
  border-bottom: 1px solid #dfdfdf;

  &:first-child {
    border-top: 1px solid #dfdfdf;
    margin-top: -1px;
  }
}

.jqx-grid-content > div > div {
  & .jqx-grid-cell-metro {
    border-bottom: 1px solid #dfdfdf;
  }
}
.jqx-grid-header-metro {
  overflow: visible !important;
  & > div {
    border-bottom: 1px solid #dfdfdf !important;
  }
}

.jqx-grid-empty-cell {
  border: none !important;
}
.ms-List-cell:nth-child(odd),
.jqx-grid-cell-alt-metro {
  background: #f5f5f5;
}

.visibilityCheckbox {
  align-self: center;
}

.labelIcon {
  font-size: 14px;
  margin: auto;
  & > i {
    font-size: 48px;
    width: 100%;
    text-align: center;
    opacity: 0.6;
  }
}
