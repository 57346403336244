@import "fonts/fonts.scss";

html {
  margin: 0;
  padding: 0;
  height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: "Segoe UI", "Webly Sleek", "Helvetica Neue", Helvetica, Arial,
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span[id^="jqxWidget"] {
  color: transparent !important;
}

body::after {
  @media (max-width: 1220px) {
    position: fixed;
    height: 25px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    line-height: 23px;
    transform: rotate(-45deg);
    z-index: 2;
    pointer-events: none;
    content: "Confidencial";
    background: #68810a;
    font-size: 10px;
    top: 18px;
    left: -24px;
    width: 110px;
  }
  @media (min-width: 1221px) {
    content: "Los datos aquí mostrados son confidenciales. Su distribución no está permitida bajo el acuerdo de confidencialidad firmado con Ameba Research.";
    background: rgba(210, 210, 210, 0.1);
    backdrop-filter: blur(8px);
    font-size: 10.5px;
    position: fixed;
    width: 100%;
    height: 24px;
    bottom: 0;
    left: 0;
    text-align: center;
    //color: rgba(255, 255, 255, 0.65);
    opacity: 0.8;
    line-height: 24px;
    z-index: 2;
    pointer-events: none;
  }
}

body.dev::after {
  content: "dev";
  background: #0e6773;
  font-size: 13px;
}
body.mvr::after {
  content: "Modo prueba";
  background: #4d158d;
  font-size: 10px;
  top: 18px;
  left: -24px;
  width: 110px;
}
body.test::after {
  content: "test";
  background: #7d1152;
  font-size: 13px;
}
body.app::after {
  content: "interno";
  background: #7d0808;
  font-size: 11px;
}
body:is(.dev, .mvr, .test, .app)::after {
  position: fixed;
  width: 100px;
  height: 25px;
  top: 12px;
  left: -26px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  line-height: 23px;
  transform: rotate(-45deg);
  z-index: 2;
  pointer-events: none;
}
