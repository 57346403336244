$primary: #007d92;
main.admin{
	background: #cac9c9;
	margin-left: 241px;
    min-height: calc(100vh - 80px);
	padding-left: 60px;
	padding-right: 60px;
	width: 100%;
}
.section{
	padding-top: 40px;
	padding-bottom: 40px;
}
header.admin{
	width: 240px;
	margin: 0;
	padding: 0;
	height: 100vh;
	position: fixed;
	//padding: 50px 15px 50px 30px;
	background: #e1e0de;
	border-right: 1px solid #e6e4e3;
	box-shadow: 0 0 10px rgba(0,0,0,0.03);

	nav{
		padding: 50px 40px 50px 30px;
	}

	.header-img{
		padding-bottom: 20px;
	}

	.navlist{
		user-select: none;
		label{
			font-weight: 400;
			font-size: 13px;
			letter-spacing: -0.3px;
			color: #888888;
			text-transform: uppercase;
		}
		ol{
			font-weight: 400;
			font-size: 13px;
			letter-spacing: -0.2px;
			list-style: none;
			padding: 6px 0 16px 0;
			margin: 0;
			cursor: pointer;
		}
		li{
			color: #222222;
			padding: 4px 20px;
			//border-left: 2px solid transparent;
			border: 1px solid transparent;
			background-color: transparent;
			transition: background-color .3s;
			&::before{
				content: "";
				transition: height .2s;
				position: absolute;
				margin-top: 3px;
				left: 36px;
				width: 0;
				height: 0;
				background-color: $primary;
				//opacity: 0;
			}

			&:not(.disabled):hover{
				background-color: rgba(48, 156, 169, 0.06);
				//border-color: $primary;
				&::before{
					width: 2px;
					height: 1em;
				}
			}
			&:not(.disabled).active{
				color: $primary;
				//border-color: $primary;
				&::before{
					width: 2px;
					height: 1em;
				}
			}
		}

		.disabled{
			opacity: 0.5;
			cursor: default;
		}
		.collapsed {
			height: 1.8em;
		}
	}
}
.spacer {
    margin-top: 50px;
}