section:not(.DL){
	.manual-icon{
		display: none;
	}
}
.manual-icon{
	@media (max-width: 900px) {	// isMobile
		margin-top: -6px;
		& .apexcharts-toolbar{
			padding: 0px 6px !important;
			background: #6e6e6e1f;
		}
	}
	padding: 0 0 6px 8px;
    margin-top: -4px;
    align-self: center;
	position: relative;
    top: 0.5em;
	z-index: 1000;

	//&:hover{
	//	opacity: 1;
	//}

	& .apexcharts-menu{
		transform: scale(1.2) translate(-2px, 4px);
		min-width: 130px;
		background: rgba(0,0,0,0.4);
		border: 1px solid rgba(0,0,0,0.6);
		backdrop-filter: blur(8px);
	}

	& .apexcharts-toolbar{
		padding: 0;
		position: relative;
		align-content: center;
		display: flex;
		align-self: center;
		justify-content: center;
		height: 32px;
		margin: auto;
		
		cursor: pointer;
		//opacity: 0.8;
		//&:hover{
		//	opacity: 1;
		//}

		& .apexcharts-menu-icon{
			width: unset;
			height: 24px;
			transform: unset;
		}
	}

	& .apexcharts-menu-item{
		color: white;
		border-radius: 3px;
		padding: 6px 7px 7px 7px;
		&:hover {
			background: rgba(255,255,255, 0.2);
		}
	}
}

.scrim{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
	pointer-events: none;
	z-index: 900;
	&.open{
		pointer-events: all;
	}
}