// .global-container {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 8px;
// }

/*.cadenas-container{
	order: 2;
	flex-grow: 0.25;
	align-self: flex-start;
	padding: 22px 34px;
    background: #282828;
    border-radius: 3px;
	border: 1px solid rgba(255, 255, 255, 0.14);
	box-shadow: 0px 1px 10px 0px rgba(6, 6, 6, 0.2);
	user-select: none;
	
	ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin: 10px 0 0 0;
		padding: 0;
	}

	li{
		list-style: none;
		font-size: 12px;
		margin: 0 12px 4px 12px;
	}

	label{
		font-weight: 400;
		font-size: 13px;
		letter-spacing: -0.3px;
		color: #888888;
		text-transform: uppercase;
	}

	.notification{
		background: #202020;
		padding: 12px 18px;
		border-radius: 3px;
		border-left: 4px solid #181818;
		font-size: 12px;
	}
}
*/
.eventos-container {
  order: 1;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.cadenas-container {
  order: 2;
  flex-grow: 1;
}

.eventos-container,
.cadenas-container {
  user-select: none;

  .eventos {
    margin-bottom: 2em;
  }

  .title,
  label {
    font-size: 17px;
    //color: var(--primary-color);
    color: #0098b2;
    letter-spacing: -0.4px;
  }

  // li {
  //   font-weight: 100;
  //   font-size: 14px;
  //   margin: 6px 0;
  //   letter-spacing: 0.2px;
  // }

  .notification {
    padding: 12px 18px;
    border-radius: 3px;
    border-left: 4px solid #181818;
    background: #282828;
    font-size: 12px;
    // margin-top: 1rem;
    //max-width: 500px;

    label {
      font-weight: 600;
      font-size: 13px;
      color: #888888;
      text-transform: uppercase;
      line-height: 2.5em;
    }

    ul {
      list-style: none;
      margin: 0 0 10px;
      padding-left: 0px;
      display: grid;
      grid-template-columns: repeat(auto-fill, max(75px,6vw));
      grid-gap: 4px 0;
      justify-content: space-between;
	  width: calc(100% - 4px);

      li {
        font-size: 12px;
        letter-spacing: -0.4px;
        text-overflow: ellipsis;
        overflow-x: clip;
        white-space: nowrap;
        line-height: 12px;
        font-weight: 500;
      }
    }
  }
}

// @media only screen and (max-width: 380px) {
//   .cadenas-container .notification ul {
//     grid-template-columns: repeat(4, 1fr);
//     padding-left: 10px;
//     li {
//       line-height: 1rem;
//     }
//   }
// }