.mapTooltip{
	font-weight: 600;
	font-size: 0.9em !important;
    letter-spacing: -0.2px;
}

// isMobile
@media (max-width: 900px) {
	.map-container{
		margin: -24px -24px -54px -24px;
	}
}

.map-container{
	outline: none;
}

.region-total{
	display: flex;
	position: relative;
    height: 22px;
    opacity: 0.9;
    bottom: 72px;
    float: right;
	user-select: none;

	svg{
		width: 34px;
		height: 28px;
		transform: translateY(-3px);
		margin-right: 8px;
	}
	span{
		padding-left: 4px;
		font-size: 14.5px;
		font-weight: 300;
		line-height: 22px;
		strong{
			font-weight: 400;
		}
	}
}



.map-text-container{
	position: relative;
	align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
	min-width: 80px;
	min-height: 80px;
	pointer-events: none;
}

.map-text{
	position: absolute;
	background-color: #0000004d;
    backdrop-filter: blur(6px);
    padding: 0;
    list-style: none;
    padding: 8px 20px;
	margin: 0;
    border-radius: 3px;
    font-weight: 200;
	width: min-content;
	font-family: "Segoe UI", "Webly Sleek", "Helvetica Neue", Helvetica, Arial;
	font-size: 13px;
	transition: background-color 0.3s;

	&.active{
		background-color: #000000a8;
	}

	& li{
		padding: 0;
		margin: 0;
		display: inline-flex;
		align-items: center;
		line-height: 22px;
		width: max-content;
		gap: 6px;
		
		& div{
			min-width: 11px;
			min-height: 11px;
			position: relative;
			display: block;
			border-radius: 11px;
			border: 1px solid #0000001f;
		}
	}
}


.regiones-totales{
    background: #00000030;
	display: flex;
    position: relative;
    height: 22px;
    opacity: 0.9;
    bottom: 100px;
	margin-bottom: -100px;
    float: right;
    padding: 18px 20px 14px 20px;
    border-radius: 4px;
    user-select: none;
    flex-flow: column;
    gap: 11px;
    height: auto;
    width: auto;

	svg{
		width: 34px;
		height: 28px;
		transform: translateY(-3px);
		margin-right: 8px;
	}
	span{
		padding-left: 4px;
		font-size: 14.5px;
		font-weight: 300;
		line-height: 22px;
		strong{
			font-weight: 400;
		}
	}

	.region{
		display: flex;
	}
}