.beta-warning {
  font-family: "Segoe UI", "Webly Sleek", "Helvetica Neue", Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  display: flex;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  font-weight: bold;

  @media (max-width: 900px) {
    display: none;
  }

  p {
    background-color: #166772;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.3rem 0;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
  }
  a {
    transition: color 0.25s ease-in-out;
    text-decoration: none;
  }
  a:link {
    color: #b1dbfa;
  }
  a:hover {
    color: white;
  }
  a:visited {
    color: white;
  }
}
