.loading-graph {
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: transparent;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.25);

  p {
    color: white;
    margin: 0.5rem 0 0 0;
    font-size: 0.875rem;
  }

  /*
  @media (min-width: 1220px) {
    // desktop
    width: calc(100% - 660px);
  }
  @media (max-width: 1220px) {
    // tablet
    width: calc(100% - 340px);
  }
  @media (max-width: 900px) {
    // mobile
    width: calc(100% - 60px);
  }*/
}

.test-div {
  color: red;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: row;
  margin-top: 3rem;
}

.mini-graph {
  width: 50%;
  position: relative;
  margin: 1rem 0;
  border: 1px dotted gray;
  box-sizing: border-box;
  padding: 0;
}

.mini-graph:hover {
  cursor: pointer;
}

.mini-graph:first-of-type {
  margin-right: 0.5rem;
}
.mini-graph:last-of-type {
  margin-left: 0.5rem;
}

.chart-container {
  position: relative;
  min-height: "450px";
}

.mini-graph .chart-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.chart-container .chart-wrapper {
  position: relative;
  width: 100%;
}
