
.check-img{
	width: 17px;
    height: 16px;
    background-repeat: no-repeat;
	background-size: contain;
	opacity: 0.9;
	margin-left: 6px;
}
.check-yes{
    background-image: url('img/check-yes.svg');	
}
.check-no{
	background-image: url('img/check-no.svg');	
}