$color-up: #c5e54e;
$color-down: #ff367a;
$color-equal: #cccccc;

.counter {
  user-select: none;
  .counter-number {
    font-size: 32px;
    font-weight: bold;
    color: var(--text-color);
  }
  .counter-title {
    font-size: 17px;
    padding-bottom: 5px;
    //color: var(--primary-color);
    color: #0098b2;
    letter-spacing: -0.4px;
  }
  .content-growth {
    display: flex;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    padding-top: 4px;
    letter-spacing: -0.4px;
    position: relative;
    .up,
    .absolute {
      color: $color-up;
    }
    .down {
      color: $color-down;
    }
    .up,
    .down {
      margin-left: 13px;
      font-weight: bold;
    }
    .equal {
      color: $color-equal;
      font-weight: bold;
      margin-left: 15px;
    }
    .text {
      color: #717171;
      padding-left: 9px;
    }

    .up:before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      margin-top: 6px;
      margin-left: -13px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 8px solid $color-up;
      animation: goup 1.5s infinite;
    }

    .down:before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      margin-top: 7px;
      margin-left: -13px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 8px solid $color-down;
      animation: godown 1.5s infinite;
    }

    .equal:before {
      content: "=";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      font-size: 16px;
      margin-top: -2px;
      margin-left: -15px;
      animation: goleft 1.5s infinite;
    }
  }
}

@keyframes goup {
  0% {
    margin-top: 6px;
  }
  50% {
    margin-top: 4px;
  }
  51% {
    margin-top: 6px;
  }
  100% {
    margin-top: 6px;
  }
}
@keyframes godown {
  0% {
    margin-top: 7px;
  }
  50% {
    margin-top: 9px;
  }
  51% {
    margin-top: 7px;
  }
  100% {
    margin-top: 7px;
  }
}
@keyframes goleft {
  0% {
    margin-left: -15px;
  }
  50% {
    margin-left: -13px;
  }
  51% {
    margin-left: -15px;
  }
  100% {
    margin-left: -15px;
  }
}
