.graph-not-compatible {
  color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  p {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 1rem 0.5rem;
  }
}
