.table-container.small{
	opacity: 1;
	filter: blur(0px);
	&.loading{
		opacity: 0.8;
		filter: blur(2px);
		pointer-events: none;
	}

	.table{
		width: 40%;
		min-width: 360px;
	}

	.row{
		width: 100%;
	}

	.cell {
		&.field, &.value, &.headercell{
			width: 80px;
		}
		&.small{
			width: 26px;
		}

		&.incomplete {
			font-weight: 500;
			color: #8e6903;
			&::after{
				content: "⚠️";
				margin-left: 5px;
				transform: translateY(-1px);
				position: absolute;
			}
		}
		&.empty{
			font-weight: 500;
    		color: #b30000;
			&::after{
				content: "❗";
				margin-left: 5px;
				transform: translate(4px, -1px);
				position: absolute;
			}
		}
		&.overflow{
			font-weight: 700;
    		color: #0146e0;
			&::after{
				content: "?";
				font-weight: 900;
				font-size: 18px;
				margin-left: 5px;
				transform: translate(2px, -1px);
				position: absolute;
			}
		}

	}
}
