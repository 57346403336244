.ms-Dropdown-callout {
  margin: 6px 0px;
}
.ms-Dropdown-header,
.ms-Dropdown-header ~ button {
  padding-left: 14px;
  padding-right: 14px;
}
.ms-Dropdown-header {
  margin-top: 4px;
  & ~ button:last-child {
    margin-bottom: 4px;
  }
}

.dropdown-container-second-level {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.dropdown-container-second-level > * {
  border-color: transparent;
}

.dropdown-container-second-level .ms-Dropdown-container {
  width: 100%;
  border-color: transparent;
}

.dropdown-container-second-level .ms-Dropdown-container:focus::after {
  border-color: transparent;
}

.dropdown-container-second-level
  .ms-Dropdown-container:hover
  .ms-Dropdown-title,
.dropdown-container-second-level .ms-Dropdown-container .ms-Dropdown-title {
  border-color: transparent;
}

.dropdown-container-second-level .ms-Dropdown-container {
  outline: none;
}
.dropdown-container-second-level .ms-Dropdown:focus::after {
  border: none;
}

.dropdown-title {
  position: relative;
}
