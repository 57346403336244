/*
$border-color: #e5e5e5;
$color_boulder_approx: #767676;
$white: #fff;
$header-bg: #f4f4f4;
$hover-bg: #dedede;
$black: #000;
$color_silver_approx: #cdcdcd;
$primary_light: #09a5bd;
$even-bg: #f0f0f0;
$color_silver_chalice_approx: #a6a6a6;
$color_dove_gray_approx: #606060;
$color_dodger_blue_approx: rgb(31, 174, 255);
*/
//edebe9
//margin: 4px 8px;
$border-color: #edebe9;
$color_boulder_approx: #767676;
$white: #fff;
$header-bg: $white;
$hover-bg: rgb(243, 242, 241);
$black: rgb(32, 31, 30);
$color_silver_approx: #cdcdcd;
$primary_light: #09a5bd;
$even-bg: #fbfbfb;
$color_silver_chalice_approx: #a6a6a6;
$color_dove_gray_approx: #606060;
$color_dodger_blue_approx: rgb(31, 174, 255);

//fonts
$font_0: segoe ui;
$font_1: arial;
$font_2: sans-serif;

//urls
$url_0: url(images/metro-icon-down.png);
$url_1: url(images/metro-icon-up.png);
$url_2: url(images/metro-icon-left.png);
$url_3: url(images/metro-icon-right.png);
$url_4: url(images/metro-icon-up-white.png);
$url_5: url(images/metro-icon-down-white.png);
$url_6: url(images/metro-icon-left-white.png);
$url_7: url(images/metro-icon-right-white.png);
$url_8: url(images/close.png);
$url_9: url(images/metro-icon-first.png);
$url_10: url(images/metro-icon-last.png);
$url_11: url(images/metro-icon-first-white.png);
$url_12: url(images/metro-icon-last-white.png);
$url_13: url(images/icon-calendar-white.png);

//@extend-elements
//original selectors
//.jqx-widget-metro .jqx-grid-column-header-metro, .jqx-grid-cell-metro, .jqx-widget-metro .jqx-grid-cell-metro, .jqx-widget-metro .jqx-grid-group-cell-metro, .jqx-grid-group-cell-metro
%extend_1 {
	font-size: 12px;
	border-color: $even-bg;
	border-right: none;
	//margin: 4px 8px;
}

//original selectors
//.jqx-tabs-title-selected-bottom-metro, .jqx-tabs-selection-tracker-bottom-metro, .jqx-tabs-title-selected-top-metro, .jqx-tabs-selection-tracker-top-metro
%extend_2 {
	color: $color_boulder_approx;
	border-color: $border-color;
	border-bottom: 1px solid $white;
	background: $white;
}

//original selectors
//.jqx-window-header-metro, .jqx-input-button-header-metro, .jqx-calendar-title-header-metro, .jqx-grid-metro .jqx-widget-header-metro, .jqx-grid-header-metro, .jqx-grid-column-header-metro
%extend_3 {
	font-size: 12px;
	border-color: $border-color;
	color: $color_boulder_approx;
	background: $header-bg;
}

//original selectors
//.jqx-widget-metro .jqx-grid-cell-selected-metro, .jqx-grid-cell-selected-metro
%extend_4 {
	background-color: $primary_light;
	border-color: $primary_light;
	font-size: 12px;
	color: $white !important;
}

//original selectors
//.jqx-menu-item-arrow-up-selected-metro, .jqx-icon-arrow-up-selected-metro
%extend_5 {
	background-image: $url_4;
	background-repeat: no-repeat;
	background-position: center;
}

//original selectors
//.jqx-menu-item-arrow-down-selected-metro, .jqx-icon-arrow-down-selected-metro
%extend_6 {
	background-image: $url_5;
	background-repeat: no-repeat;
	background-position: center;
}

//original selectors
//.jqx-menu-item-arrow-left-selected-metro, .jqx-icon-arrow-left-selected-metro
%extend_7 {
	background-image: $url_6;
	background-repeat: no-repeat;
	background-position: center;
}

//original selectors
//.jqx-menu-item-arrow-right-selected-metro, .jqx-icon-arrow-right-selected-metro
%extend_8 {
	background-image: $url_7;
	background-repeat: no-repeat;
	background-position: center;
}

//original selectors
//.jqx-grid-group-expand-metro, .jqx-grid-group-expand-rtl-metro
%extend_9 {
	padding-right: 0;
	background-image: $url_0;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}


.jqx-grid {
    box-shadow: 0 0 7px rgba(0,0,0,0.09);
    padding: 2px;
    margin: -1px;
}



.ms-Dialog-subText{
	margin: 0px 0px 12px;
}
.ms-Dialog-actions{
	margin: 26px 0px 0px;
}

.jqx-container{
	z-index: 1;
	opacity: 1;
	filter: blur(0px);
	&.loading{
		opacity: 0.8;
		filter: blur(2px);
		pointer-events: none;
	}
	.ms-FocusZone{
		transform: translateY(-3px);
	}
}

.hidden-col{
	display: none;
	/** {
		text-overflow: clip !important;
		overflow: hidden !important;
	}*/
}

.jqx-rc-tl-metro {
	//Instead of the line below you could use @include border-top-left-radius($radius)
	border-top-left-radius: 0;
}
.jqx-rc-tr-metro {
	//Instead of the line below you could use @include border-top-right-radius($radius)
	border-top-right-radius: 0;
}
.jqx-rc-bl-metro {
	//Instead of the line below you could use @include border-bottom-left-radius($radius)
	border-bottom-left-radius: 0;
}
.jqx-rc-br-metro {
	//Instead of the line below you could use @include border-bottom-right-radius($radius)
	border-bottom-right-radius: 0;
}
.jqx-rc-t-metro {
	//Instead of the line below you could use @include border-top-left-radius($radius)
	border-top-left-radius: 0;
	//Instead of the line below you could use @include border-top-right-radius($radius)
	border-top-right-radius: 0;
}
.jqx-rc-b-metro {
	//Instead of the line below you could use @include border-bottom-left-radius($radius)
	border-bottom-left-radius: 0;
	//Instead of the line below you could use @include border-bottom-right-radius($radius)
	border-bottom-right-radius: 0;
}
.jqx-rc-r-metro {
	//Instead of the line below you could use @include border-top-right-radius($radius)
	border-top-right-radius: 0;
	//Instead of the line below you could use @include border-bottom-right-radius($radius)
	border-bottom-right-radius: 0;
}
.jqx-rc-l-metro {
	//Instead of the line below you could use @include border-top-left-radius($radius)
	border-top-left-radius: 0;
	//Instead of the line below you could use @include border-bottom-left-radius($radius)
	border-bottom-left-radius: 0;
}
.jqx-rc-all-metro {
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 4px;
}
.jqx-widget-metro {
	font-size: 12px;
	.jqx-grid-column-header-metro {
		@extend %extend_1;
		color: $black;
		border-color: $border-color;
		font-weight: bold;
		&:hover{
			background: $hover-bg;
			& .jqx-widget-header{
				background-color: $hover-bg;
			}
		}
	}
	.jqx-grid-cell-metro {
		@extend %extend_1;
		color: $black;
		border-color: $border-color;
	}
	.jqx-grid-group-cell-metro {
		@extend %extend_1;
		color: $black;
		border-color: $border-color;
	}
	.jqx-grid-column-menubutton-metro {
		background-color: transparent;
		border-color: $border-color;
	}
	.jqx-grid-column-sortascbutton-metro {
		background-color: transparent;
		border-color: $border-color;
	}
	.jqx-grid-column-sortdescbutton-metro {
		background-color: transparent;
		border-color: $border-color;
	}
	.jqx-grid-column-filterbutton-metro {
		background-color: transparent;
		border-color: $border-color;
	}
	.jqx-grid-cell-selected-metro {
		@extend %extend_4;
	}
}
.jqx-widget-content-metro {
	font-size: 12px;
	border-color: $border-color;
	color: $color_boulder_approx;
	background-color: $white;
}
.jqx-widget-header-metro {
	font-size: 12px;
	color: $color_boulder_approx;
	border-color: $border-color;
	background-color: $header-bg;
}
.jqx-fill-state-normal-metro {
	font-size: 12px;
	border-color: $border-color;
	color: $color_boulder_approx;
	background: $white;
}
.jqx-button-metro {
	border-color: $border-color;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-fill-state-hover-metro {
	border-color: $hover-bg;
	color: $black;
	background-color: $hover-bg;
}
.jqx-fill-state-focus-metro {
	border-color: $color_silver_approx;
}
.jqx-fill-state-pressed-metro {
	border-color: $primary_light;
	color: $white;
	background-color: $primary_light;
}
.jqx-input-metro {
	border-color: $border-color;
}
.jqx-scrollbar-state-normal-metro {
	background-color: $even-bg;
}
.jqx-grid-bottomright-metro {
	background-color: $even-bg;
}
.jqx-panel-bottomright-metro {
	background-color: $even-bg;
}
.jqx-listbox-bottomright-metro {
	background-color: $even-bg;
}
.jqx-grid-cell-metro {
	@extend %extend_1;
	&.jqx-grid-cell-selected-metro > {
		.jqx-grid-group-expand-metro {
			background-image: $url_5;
			background-repeat: no-repeat;
			background-position: center;
		}
		.jqx-grid-group-collapse-metro {
			background-image: $url_7;
			background-repeat: no-repeat;
			background-position: center;
		}
		.jqx-grid-group-collapse-rtl-metro {
			background-image: $url_6;
			background-repeat: no-repeat;
			background-position: center;
		}
		.jqx-grid-group-expand-rtl-metro {
			background-image: $url_5;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}
.jqx-grid-group-cell-metro {
	@extend %extend_1;
}
.jqx-tabs-title-selected-bottom-metro {
	@extend %extend_2;
}
.jqx-tabs-selection-tracker-bottom-metro {
	@extend %extend_2;
}
.jqx-tabs-title-selected-top-metro {
	@extend %extend_2;
}
.jqx-tabs-selection-tracker-top-metro {
	@extend %extend_2;
}
.jqx-grid-cell-sort-alt-metro {
	background-color: $even-bg;
	color: $black;
}
.jqx-grid-cell-filter-alt-metro {
	background-color: $even-bg;
	color: $black;
}
.jqx-grid-cell-pinned-metro {
	background-color: $even-bg;
	color: $black;
}
.jqx-grid-cell-alt-metro {
	background-color: $even-bg;
	color: $black;
}
.jqx-grid-cell-sort-metro {
	background-color: $even-bg;
	color: $black;
}
.jqx-menu-vertical-metro {
	background: $white;
	border-color: $border-color;
}
.jqx-window-header-metro {
	@extend %extend_3;
}
.jqx-input-button-header-metro {
	@extend %extend_3;
}
.jqx-calendar-title-header-metro {
	@extend %extend_3;
}
.jqx-grid-header-metro {
	@extend %extend_3;
}
.jqx-grid-column-header-metro {
	@extend %extend_3;
}
.jqx-grid-column-menubutton-metro {
	background-image: $url_0;
}
.jqx-grid-cell-selected-metro {
	@extend %extend_4;
}
.jqx-grid-cell-hover-metro {
	background-color: $hover-bg;
}
.jqx-grid-column-sortascbutton-metro {
	background-image: $url_1;
}
.jqx-grid-column-sortdescbutton-metro {
	background-image: $url_0;
}
.jqx-checkbox-hover-metro {
	background-color: $white;
}
.jqx-radiobutton-hover-metro {
	background-color: $white;
}
.jqx-scrollbar-thumb-state-normal-horizontal-metro {
	background: $color_silver_approx;
	border-color: $color_silver_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-scrollbar-thumb-state-normal-metro {
	background: $color_silver_approx;
	border-color: $color_silver_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-scrollbar-thumb-state-hover-horizontal-metro {
	background: $color_silver_chalice_approx;
	border-color: $color_silver_chalice_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-scrollbar-thumb-state-hover-metro {
	background: $color_silver_chalice_approx;
	border-color: $color_silver_chalice_approx;
}
.jqx-scrollbar-thumb-state-pressed-horizontal-metro {
	background: $color_dove_gray_approx;
	border-color: $color_dove_gray_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-scrollbar-thumb-state-pressed-metro {
	background: $color_dove_gray_approx;
	border-color: $color_dove_gray_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-scrollbar-button-state-normal-metro {
	border: 1px solid $even-bg;
	background: $even-bg;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-scrollbar-button-state-hover-metro {
	border: 1px solid $hover-bg;
	background: $hover-bg;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-scrollbar-button-state-pressed-metro {
	border: 1px solid $color_dove_gray_approx;
	background: $color_dove_gray_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-window-collapse-button-metro {
	background-image: $url_1;
}
.jqx-window-collapse-button-collapsed-metro {
	background-image: $url_0;
}
.jqx-icon-arrow-up-metro {
	background-image: $url_1;
}
.jqx-expander-arrow-bottom-metro {
	background-image: $url_1;
}
.jqx-menu-item-arrow-up-metro {
	background-image: $url_1;
}
.jqx-icon-arrow-down-metro {
	background-image: $url_0;
}
.jqx-expander-arrow-top-metro {
	background-image: $url_0;
}
.jqx-tree-item-arrow-expand-metro {
	background-image: $url_0;
}
.jqx-tree-item-arrow-expand-hover-metro {
	background-image: $url_0;
}
.jqx-menu-item-arrow-down-metro {
	background-image: $url_0;
}
.jqx-icon-arrow-left-metro {
	background-image: $url_2;
}
.jqx-menu-item-arrow-left-metro {
	background-image: $url_2;
}
.jqx-icon-arrow-right-metro {
	background-image: $url_3;
}
.jqx-menu-item-arrow-right-metro {
	background-image: $url_3;
}
.jqx-tree-item-arrow-collapse-metro {
	background-image: $url_3;
}
.jqx-tree-item-arrow-collapse-hover-metro {
	background-image: $url_3;
}
.jqx-tabs-arrow-left-metro {
	background-image: $url_2;
}
.jqx-tree-item-arrow-collapse-rtl-metro {
	background-image: $url_2;
}
.jqx-tree-item-arrow-collapse-hover-rtl-metro {
	background-image: $url_2;
}
.jqx-tabs-arrow-right-metro {
	background-image: $url_3;
}
.jqx-menu-item-arrow-up-selected-metro {
	@extend %extend_5;
}
.jqx-icon-arrow-up-selected-metro {
	@extend %extend_5;
}
.jqx-menu-item-arrow-down-selected-metro {
	@extend %extend_6;
}
.jqx-icon-arrow-down-selected-metro {
	@extend %extend_6;
}
.jqx-menu-item-arrow-left-selected-metro {
	@extend %extend_7;
}
.jqx-icon-arrow-left-selected-metro {
	@extend %extend_7;
}
.jqx-menu-item-arrow-right-selected-metro {
	@extend %extend_8;
}
.jqx-icon-arrow-right-selected-metro {
	@extend %extend_8;
}
.jqx-tabs-close-button-metro {
	background-image: $url_8;
	background-repeat: no-repeat;
	background-position: center;
}
.jqx-tabs-close-button-selected-metro {
	background-image: $url_8;
	background-repeat: no-repeat;
	background-position: center;
}
.jqx-tabs-close-button-hover-metro {
	background-image: $url_8;
	background-repeat: no-repeat;
	background-position: center;
}
.jqx-scrollbar-metro {
	.jqx-icon-arrow-up-selected-metro {
		background-image: $url_4;
		background-repeat: no-repeat;
		background-position: center;
	}
	.jqx-icon-arrow-down-selected-metro {
		background-image: $url_5;
		background-repeat: no-repeat;
		background-position: center;
	}
	.jqx-icon-arrow-left-selected-metro {
		background-image: $url_6;
		background-repeat: no-repeat;
		background-position: center;
	}
	.jqx-icon-arrow-right-selected-metro {
		background-image: $url_7;
		background-repeat: no-repeat;
		background-position: center;
	}
}
.jqx-slider-slider-metro {
	border-color: $color_silver_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-slider-button-metro {
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 9px;
	border-color: $color_silver_approx;
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-input-button-content-metro {
	font-size: 10px;
}
.jqx-dropdownlist-state-normal-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-dropdownlist-state-hover-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-dropdownlist-state-selected-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-tree-item-hover-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-tree-item-selected-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-tree-item-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-menu-item-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-menu-item-hover-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-menu-item-selected-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-menu-item-top-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-menu-item-top-hover-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-menu-item-top-selected-metro {
	//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: background-color 100ms linear;
}
.jqx-switchbutton-metro {
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0;
	border: 2px solid $color_silver_chalice_approx;
}
.jqx-switchbutton-thumb-metro {
	width: 12px;
	background: $black;
	border: 1px solid $black;
}
.jqx-switchbutton-label-on-metro {
	background: $primary_light;
	color: $primary_light;
}
.jqx-switchbutton-label-off-metro {
	background: $color_silver_chalice_approx;
	color: $color_silver_chalice_approx;
}
.jqx-switchbutton-wrapper-metro {
}
.jqx-grid-group-collapse-metro {
	background-image: $url_3;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.jqx-grid-group-collapse-rtl-metro {
	padding-right: 0;
	background-image: $url_2;
	background-position: 50% 50%;
	background-repeat: no-repeat;
}
.jqx-grid-group-expand-metro {
	@extend %extend_9;
}
.jqx-grid-group-expand-rtl-metro {
	@extend %extend_9;
}
.jqx-icon-arrow-first-metro {
	background-image: $url_9;
	background-repeat: no-repeat;
	background-position: center;
}
.jqx-icon-arrow-last-metro {
	background-image: $url_10;
	background-repeat: no-repeat;
	background-position: center;
}
.jqx-icon-arrow-first-hover-metro {
	background-image: $url_9;
	background-repeat: no-repeat;
	background-position: center;
}
.jqx-icon-arrow-last-hover-metro {
	background-image: $url_10;
	background-repeat: no-repeat;
	background-position: center;
}
.jqx-icon-arrow-first-selected-metro {
	background-image: $url_11;
	background-repeat: no-repeat;
	background-position: center;
}
.jqx-icon-arrow-last-selected-metro {
	background-image: $url_12;
	background-repeat: no-repeat;
	background-position: center;
}
.jqx-tree-grid-collapse-button-metro {
	margin-top: 1px;
}
.jqx-icon-calendar-pressed-metro {
	background-image: $url_13;
}
.jqx-layout-metro {
	background-color: $border-color;
}
.jqx-svg-picker-metro {
	border: 1px solid $color_dodger_blue_approx;
	&:focus {
		border: 1px solid $color_dodger_blue_approx !important;
	}
}
.jqx-needle-central-circle-metro {
	fill: $color_dodger_blue_approx;
}
.jqx-needle-metro {
	fill: $color_dodger_blue_approx;
}
.jqx-grid-metro .jqx-widget-header-metro {
	@extend %extend_3;
}
.jqx-time-picker .jqx-header {
	.jqx-hour-container-metro:focus {
		outline: 1px solid $color_dodger_blue_approx !important;
	}
	.jqx-minute-container-metro:focus {
		outline: 1px solid $color_dodger_blue_approx !important;
	}
	.jqx-am-container-metro:focus {
		outline: 1px solid $color_dodger_blue_approx !important;
	}
	.jqx-pm-container-metro:focus {
		outline: 1px solid $color_dodger_blue_approx !important;
	}
}