.falseHeader{
	display: flex;
	width: 100%;
    justify-content: flex-end;

	& .ms-FocusZone{
		height: 36px;
	}

	& .ms-Spinner{
		height: 36px;
    	margin-right: 20px;
	}

	& .ms-Checkbox{
		align-self: center;
	}
	& .ms-Checkbox-text{
		font-size: 12px;
		line-height: 22px;
	}

	& > div:last-child > div{
		top: -2px;
	}
}

.ms-Stack.group{
	padding: 0 4px;
	background: #d4e4e6;
	border-radius: 2px;
	border: 1px solid #bedadd;
}

.ms-Dropdown-container.grouped{
	& span{
		height: 28px;
		line-height: 27px;
		margin-top: 2px;
	}
}