
/* TypoPRO WeblySleek UI Light */
@font-face {
    font-family:  'Webly Sleek';
    src:          url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-Light.eot');
    src:          local('TypoPRO-WeblySleek'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-Light.eot?#iefix') format('embedded-opentype'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-Light.woff') format('woff'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-Light.ttf') format('truetype');
    font-style:   normal;
    font-weight:  300;
    font-stretch: normal;
    font-variant: normal;
}


/* TypoPRO WeblySleek UI Light Italic */
@font-face {
    font-family:  'Webly Sleek';
    src:          url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-LightItalic.eot');
    src:          local('TypoPRO-WeblySleek'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-LightItalic.eot?#iefix') format('embedded-opentype'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-LightItalic.woff') format('woff'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-LightItalic.ttf') format('truetype');
    font-style:   italic;
    font-weight:  300;
    font-stretch: normal;
    font-variant: normal;
}


/* TypoPRO WeblySleek UI Semibold */
@font-face {
    font-family:  'Webly Sleek';
    src:          url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiBold.eot');
    src:          local('TypoPRO-WeblySleek'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiBold.eot?#iefix') format('embedded-opentype'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiBold.woff') format('woff'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiBold.ttf') format('truetype');
    font-style:   normal;
    font-weight:  bold;
    font-stretch: normal;
    font-variant: normal;
}


/* TypoPRO WeblySleek UI Semibold Italic */
@font-face {
    font-family:  'Webly Sleek';
    src:          url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiBoldItalic.eot');
    src:          local('TypoPRO-WeblySleek'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiBoldItalic.woff') format('woff'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiBoldItalic.ttf') format('truetype');
    font-style:   italic;
    font-weight:  bold;
    font-stretch: normal;
    font-variant: normal;
}


/* TypoPRO WeblySleek UI Semilight */
@font-face {
    font-family:  'Webly Sleek';
    src:          url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiLight.eot');
    src:          local('TypoPRO-WeblySleek'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiLight.eot?#iefix') format('embedded-opentype'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiLight.woff') format('woff'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiLight.ttf') format('truetype');
    font-style:   normal;
    font-weight:  normal;
    font-stretch: normal;
    font-variant: normal;
}


/* TypoPRO WeblySleek UI Semilight Italic */
@font-face {
    font-family:  'Webly Sleek';
    src:          url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiLightItalic.eot');
    src:          local('TypoPRO-WeblySleek'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiLightItalic.eot?#iefix') format('embedded-opentype'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiLightItalic.woff') format('woff'),
                  url('TypoPRO-WeblySleek/TypoPRO-WeblySleek-SemiLightItalic.ttf') format('truetype');
    font-style:   italic;
    font-weight:  normal;
    font-stretch: normal;
    font-variant: normal;
}

