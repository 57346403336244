.manual-CommandBar{
	font-family: "Segoe UI", "Webly Sleek", "Helvetica Neue", Helvetica, Arial;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    display: flex;
    background-color: rgb(29, 31, 32);
    padding: 0px 14px 0px 24px;
	@media (max-width: 900px) {
		padding: 0px 14px;
		font-size: 12px;
	}
    height: 44px;
}

.table-manual{
	.table{
		width: 80%;

		.ms-OverflowSet-item, .ms-Button--commandBar{
			display: flex;
			height: 44px;
			& > span{
				margin: auto;
			}
		}

		.container{
			height: 30vh;
			overflow: scroll;
			padding: 10px 28px !important;
			mask-image: -webkit-gradient(linear, center 0%, center 95%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));

			p{
				padding-bottom: 80px;
			}
		}
	}
}
.acceptterms-container{
	width: 100%;
	text-align: center;
	padding: 30px 0 0 0;
}