$primary: #00b7d6;

main.dark {
  background: linear-gradient(-45deg, #18191a, #1d1f20);
  min-height: calc(100vh - 120px);
  padding-bottom: 120px;

  height: 100%;

  section {
    max-width: 1100px;
    margin: auto;
    padding-top: 46px;
    padding-bottom: 40px;
  }

  @media (max-width: 1220px) {
    width: calc(100vw - 330px); // tablet
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (min-width: 1221px) {
    width: calc(100vw - 675px); // desktop
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 900px) {
    margin-left: 0px;
    width: calc(100vw - 60px); // móvil
    padding-left: 30px;
    padding-right: 30px;
    section:first-child {
      padding-top: 106px;
    }
  }
  @media (min-width: 900px) {
    // no móvil
    margin-left: 241px;
  }
  @media (min-width: 1800px) {
    // big-desktop
    width: calc(100vw - 725px);
    margin-left: 281px;
  }

  &.locked {
    position: fixed;
    padding-right: 67px;
  }

  &.no-filters {
    width: 100%;
    & ~ #filters-container {
      display: none;
    }
  }
}

// isMobile
@media (max-width: 900px) {
  header.dark {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: fixed;
    z-index: 2;
    background-color: transparent;
    //pointer-events: none;
    transition: background-color 0.167s cubic-bezier(0.1, 0.25, 0.75, 0.9);

    .show-menu {
      background: transparent;
      z-index: 80000;
      position: absolute;
      width: 40px;
      height: 40px;
      top: 15px;
      left: 18px;
      color: #e5e5e5;
      border-radius: 2px;
      font-size: 22px;
      outline: none;
      border: none;
      pointer-events: all;

      & > i {
        margin-top: 6px;
      }

      &:active {
        background-color: #363737;
      }

      &:hover {
        background-color: #2e2f2f;
        color: #ffffff;
      }
    }

    &.navopen,
    &.filteropen {
      background-color: rgba(255, 255, 255, 0.4);
      pointer-events: all !important;
    }

    .header-menu {
      width: 100vw;
      height: 68px;
      background: #1b1b1bbf;
      backdrop-filter: blur(8px);
    }

    nav {
      //padding: 50px 24px 20px 30px;
      padding: 50px 18px 20px 24px;
      width: 198px;
      background: linear-gradient(0deg, #222323, #202020);
      border-right: 1px solid #38393a;
      box-shadow: none;
      height: 100vh;
      position: fixed;
      transition: left 0.5s cubic-bezier(0.34, 0.86, 0.64, 1), box-shadow 0.5s;
    }
    &.navclosed {
      pointer-events: none;
      nav {
        left: -251px;
      }
    }
    &.navopen {
      nav {
        left: 0px;
        box-shadow: rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px,
          rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
      }
      & ~ #filters-container {
        bottom: -100%;
      }
    }

    #header-img {
      height: 36px;
      z-index: 2;
      margin-left: 80px;
      margin-right: 40px;
      margin-top: 21px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      svg {
        height: 28px;
      }
      .logo {
        display: flex;
        place-content: flex-end;
      }
      .title {
        display: flex;
        place-content: flex-start;
        margin-right: 1rem;
      }
    }

    .navlist {
      user-select: none;
      label {
        font-weight: 400;
        font-size: 13px;
        letter-spacing: -0.3px;
        color: #888888;
        text-transform: uppercase;
      }
      ol {
        font-weight: 400;
        font-size: 13px;
        letter-spacing: -0.2px;
        list-style: none;
        padding: 6px 0 16px 0;
        margin: 0;
        cursor: pointer;
      }
      li {
        color: #ffffff;
        padding: 4px 20px;
        border: 1px solid transparent;
        background-color: transparent;
        transition: background-color 0.3s;
        &::before {
          content: "";
          transition: height 0.2s;
          position: absolute;
          margin-top: 3px;
          left: 32px;
          width: 0;
          height: 0;
          background-color: $primary;
          transform: rotate(180deg);
          //opacity: 0;
        }

        &:not(.disabled):hover {
          background-color: rgba(48, 156, 169, 0.2);
          //border-color: $primary;
          &::before {
            width: 2px;
            height: 1em;
          }
        }
        &:not(.disabled).active {
          color: $primary;
          //border-color: $primary;
          &::before {
            width: 2px;
            height: 1em;
          }
        }
      }

      .disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  #loadDataBtn {
    display: none;
  }
}

// big-desktop
@media (min-width: 1800px) {
  header.dark {
    width: 281px !important;
    nav {
      width: 238px !important;
    }
    .navlist ol {
      font-size: 14px !important;
    }
    #header-img {
      width: 232px !important;
    }
  }
}

// notMobile
@media (min-width: 900px) {
  header.dark {
    display: flex;
    flex-wrap: wrap;
    width: 241px;
    margin: 0;
    padding: 0;
    height: 100vh;
    position: fixed;
    position: absolute;
    z-index: 2;

    .show-menu {
      display: none;
    }

    nav {
      //padding: 50px 24px 20px 30px;
      padding: 110px 18px 20px 24px;
      width: 198px;
      background: linear-gradient(0deg, #222323, #202020);
      border-right: 1px solid #38393a;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
      height: 100vh;
      position: fixed;
      left: 0px;

      display: flex;
      flex-wrap: wrap;
      flex-flow: column;
    }

    #header-img {
      position: fixed;
      z-index: 2;
      margin-left: 24px;
      margin-top: 50px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      svg {
        height: 34px;
      }
      .logo {
        display: flex;
      }
      .title {
        margin-right: 1rem;
      }
    }

    .user-tag {
      position: relative;
      font-size: 13px;
      color: white;
      background-color: transparent;
      padding: 0;
      text-align: left;
      margin-bottom: 1rem;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;

      span {
        color: #888888;
        text-transform: uppercase;
      }

      p {
        margin: 0;
      }
    }

    .navlist {
      user-select: none;
      label {
        font-weight: 400;
        font-size: 13px;
        letter-spacing: -0.3px;
        color: #888888;
        text-transform: uppercase;
      }
      ol {
        font-weight: 400;
        font-size: 13px;
        letter-spacing: -0.2px;
        list-style: none;
        padding: 6px 0 16px 0;
        margin: 0;
        cursor: pointer;
      }
      li {
        color: #ffffff;
        padding: 4px 20px;
        border: 1px solid transparent;
        background-color: transparent;
        transition: background-color 0.3s;
        &::before {
          content: "";
          transition: height 0.2s;
          position: absolute;
          margin-top: 3px;
          left: 32px;
          width: 0;
          height: 0;
          background-color: $primary;
          transform: rotate(180deg);
          //opacity: 0;
        }

        &:not(.disabled):hover {
          background-color: rgba(48, 156, 169, 0.2);
          //border-color: $primary;
          &::before {
            width: 2px;
            height: 1em;
          }
        }
        &:not(.disabled).active {
          color: $primary;
          //border-color: $primary;
          &::before {
            width: 2px;
            height: 1em;
          }
        }
      }

      .disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  #loadDataBtn {
    //margin: 0 auto 40px auto;
    margin: auto auto 152px auto;
    align-self: flex-end;
    span {
      font-size: 11.5px;
      font-weight: 400;
      letter-spacing: -0.4px;
    }
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: flex;
    opacity: 0;
    backdrop-filter: blur(0px);
  }

  100% {
    display: flex;
    opacity: 1;
    backdrop-filter: blur(2px);
  }
}

.modal-container.dark {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: calc(100vh - 100px);
  justify-content: center;
  padding: 50px 0;
  user-select: auto;
  pointer-events: auto;
  z-index: 12;

  &.in-section {
    z-index: 1 !important;
  }

  &.visible {
    display: flex;
    backdrop-filter: blur(2px);
    animation: fadeInFromNone 0.267s ease;
  }
  &.hidden {
    display: none;
  }

  .modal {
    width: 50vw;
    min-width: 700px;
    @media (max-width: 900px) {
      width: 90vw;
      min-width: unset;
    }
    align-self: center;
    //background: #252423;
    background: #1e1e1e;
    border-radius: 3px;
    //transition: opacity 0.267s ease 0s;
    box-shadow: rgba(0, 0, 0, 0.88) 0px 25.6px 57.6px 0px,
      rgba(0, 0, 0, 0.72) 0px 4.8px 14.4px 0px;
    outline: transparent solid 3px;
    overflow-y: auto;
    max-height: calc(100% - 32px);

    .modal-topbar {
      display: flex;
      padding-bottom: 1em;
      & > span {
        -webkit-font-smoothing: antialiased;
        font-size: 20px;
        font-weight: 600;
        color: #f3f2f1;
        margin: 0px;
        min-height: 20px;
        padding: 20px 46px 18px 25px;
        line-height: normal;
        pointer-events: none;
      }
      & > button {
        color: #f3f2f1;
        margin-top: 18px;
        margin-right: 18px;
      }
    }

    .modal-content {
      padding: 0px 24px 24px;
    }
  }
}

@media (max-width: 900px) {
  .modal-container.visible ~ #filters-container {
    display: none;
  }
}

.navlist {
  &:hover {
    cursor: pointer;
    label,
    i {
      letter-spacing: -0.2px !important;
      color: var(--text-color) !important;
    }
  }
  label {
    transition: letter-spacing 0.2s, color 0.3s;
    cursor: pointer;
  }
  ol {
    transition: height 0.3s ease-in, opacity 0.4s ease-in;
    height: 100%;
    overflow: hidden;
  }
  &.collapsed {
    ol {
      height: 0;
      opacity: 0;
    }

    .navlist-caret {
      transform: rotate(180deg);
    }
  }

  &-caret {
    color: #888888;
    font-size: 8px;
    margin-left: 8px;
    vertical-align: middle;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }
}

.is-new {
  color: #c6e35a;
  margin-left: 0.5rem;
  font-size: 14px;
  animation: goRight 1s ease-in-out infinite alternate;
}

.apple {
  color: #c6e35a;
  font-size: 14px;
}

.is-new-no-anim {
  color: #c6e35a;
  margin-left: 0.25rem;
  font-size: 14px;
}

@keyframes goRight {
  0% {
    margin-left: 7px;
  }
  50% {
    margin-left: 9px;
  }

  100% {
    margin-left: 7px;
  }
}

.main-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
