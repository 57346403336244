.counter-container {
  display: flex;
  gap: 30px 26px;
  flex-wrap: wrap;
  text-align: left;
  padding: 20px ​0px 40px 0px;
  justify-content: space-between;
  position: relative;

  @media (max-width: 900px) {
    text-align: center;
    justify-content: space-around;
  }

  .shimmer {
    //display: flex;
    //justify-content: space-between;
  }
}

.generate-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 80px;
  gap: 8px;
  justify-content: center;
  flex-grow: 1;
  & > a {
    flex-grow: 1;
  }

  & > button {
    margin: 0 5px;
  }
}
