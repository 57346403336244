// isDesktop
@media (min-width: 1220px) {
  #filters-container::-webkit-scrollbar {
    display: none;
  }

  #filters-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  #filters-container {
    background: linear-gradient(-45deg, #0d0f0f, #222323);
    width: 260px;
    position: fixed;
    right: 0;
    height: calc(100vh - 92px);
    box-shadow: inset 6px 0px 10px -10px #060606;
    border-left: 1px solid rgba(255, 255, 255, 0.14);
    padding: 46px 32px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow: auto;

    & label:not(.ms-Checkbox-label) {
      //text-transform: uppercase;
      //color: #ffffff;
      //font-size: 15px;
      //font-weight: 500;
      //height: 70px;
      display: none;
    }

    & button {
      //color: unset;
    }

    & .ms-Dropdown {
      opacity: 0.6;
      transition: opacity 0.2s;
      position: relative;
      &:not(.is-disabled):hover {
        opacity: 0.9;
      }
      &.is-disabled {
        opacity: 0.2;
      }

      .ms-Dropdown-caretDownWrapper {
        transition: transform 0.2s;
        font-size: 9px;
        color: #ffffff;
      }
      &:not(.is-open) .ms-Dropdown-caretDownWrapper {
        transform: rotate(0deg);
        margin-top: 1px;
      }
      &.is-open .ms-Dropdown-caretDownWrapper {
        transform: rotate(-180deg);
        margin-top: 0px;
      }
    }

    & .dropdown-container .ms-Dropdown-title {
      border-color: transparent;
      background: transparent;
      text-align: right;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: -0.2px;
      width: 100%;
    }

    & .dropdown-title {
      &:not(.has-subtitle) {
        transform: translateY(-29px);
        font-size: 17px;
      }
      &.has-subtitle {
        transform: translateY(-35px);
        font-size: 16px;
      }
      position: absolute;
      font-weight: 400;
      color: #e3e3e3;
      pointer-events: none;
      &.disabled {
        opacity: 0.2;
      }
    }
    & .dropdown-subtitle {
      position: absolute;
      font-size: 10px;
      transform: translateY(-14px);
      opacity: 0.7;
      font-weight: 400;
      letter-spacing: -0.1px;
      pointer-events: none;
    }

    & .ms-Dropdown-container {
      outline: none;
      border-color: transparent;
    }
    & .ms-Dropdown:focus::after {
      border: none;
      border-color: transparent;
    }

    & .dropdown-container {
      width: 100%;
      flex-grow: 1;
    }
  }

  #calendar-wrap {
    width: 100%;
  }
  #calendar-container {
    width: 100%;
    background: #242526;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 28px;
    border: 1px solid rgba(255, 255, 255, 0.14);
    box-shadow: 0px 1px 10px 0px rgba(6, 6, 6, 0.2);
    .ms-DatePicker {
      user-select: none;
      zoom: 0.9;
      padding: 8px 0;
      &-holder {
        height: unset;
        min-height: unset;
      }
      &-wrap {
        transform: scale(0.9);
        padding-bottom: 6px 12px;
        min-height: unset;
      }
      .ms-DatePicker-dayPicker,
      .ms-DatePicker-monthPicker {
        min-height: unset;
      }
      .ms-DatePicker-day {
        cursor: default;
        & button {
          cursor: unset;
        }
        &--today {
          background: inherit !important;
          color: #4c4d4e !important;
        }
        &--infocus,
        &--outfocus {
          cursor: pointer;
        }
      }
    }
  }
}

//width: calc(100vw - 40px);
//left: 0;

@media (max-width: 1220px) {
  // tablet
  #filters-container {
    width: calc(100vw - 281px);
    left: 241px;
  }
}
@media (max-width: 900px) {
  // mobile
  #filters-container {
    width: calc(100vw - 40px);
    left: 0;
  }
}

.check-container {
  width: 80%;
  background: #1b1b1b;
  border-left: 4px solid #101010;
  border-radius: 2px;
  padding: 12px 0 12px 22px;
  transform: translateY(-14px);

  &.hidden {
    display: none;
  }

  &-small {
    //width: 80%;
    //max-width: 220px;
    //background: #1b1b1b;
    //border-left: 4px solid #101010;
    //border-radius: 2px;
    //padding: 12px;
    //margin: 1em auto -1em auto;
    background: #1b1b1b;
    border-top: 1px solid #101010;
    border-radius: 0 0 3px 3px;
    padding: 16px 30px;
    margin: 40px -40px -40px -40px;
    display: flex;
    justify-content: center;
  }
}

.ms-Checkbox-Filters span {
  font-size: 13px;
  margin-top: -1px;
}

// notDesktop
@media (max-width: 1220px) {
  #filters-container {
    background: rgba(255, 255, 255, 0.09);
    backdrop-filter: blur(8px);
    position: fixed;
    //height: 374px;
    box-shadow: inset 6px 0px 10px -10px #060606;
    border-top: 1px solid rgba(255, 255, 255, 0.14);
    padding: 18px 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    z-index: 11;
    transition: transform 0.2s cubic-bezier(0.1, 0.25, 0.75, 0.9),
      bottom 0.5s cubic-bezier(0.1, 0.25, 0.75, 0.9);
    justify-content: space-between;
    &.open {
      //bottom: -1px;
      bottom: 98px;
      transform: translateY(62px);

      & > label > span::before {
        content: "Ocultar";
      }
    }
    &.closed {
      //bottom: -294px;
      bottom: 98px;
      transform: translateY(100%);

      & > label > span::before {
        content: "Mostrar";
      }
    }
    &.open.f2,
    &.closed.f2 {
      bottom: 60px;
      #calendar-container {
        margin-top: 38px;
      }
    }
    &.closed.f2 {
      bottom: 100px;
    }
    &.open.f5,
    &.closed.f5 {
      bottom: 138px;
      .dropdown-container:last-child {
        margin-right: 50%;
      }
    }
    &.open.f5 {
      //transform: translateY(129px);
      transform: translateY(140px);
    }

    .mobile-backdrop {
      width: 100%;
      height: 100%;
      background: rgba(33, 34, 34, 0.6);
      position: absolute;
      margin: -20px;
    }

    &.f2 label:not(.ms-Checkbox-label) {
      top: 53px;
    }
    &.f5 label:not(.ms-Checkbox-label) {
      top: 93px;
    }
    & label:not(.ms-Checkbox-label) {
      position: absolute;
      //background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), #252727);
      background: linear-gradient(180deg, #252727, rgba(49, 49, 49, 0.95));
      backdrop-filter: blur(8px);
      //text-transform: uppercase;
      color: #dedede;
      font-size: 12px;
      font-weight: 400;
      border-radius: 3px 0 0 3px;
      border: 1px solid rgba(255, 255, 255, 0.14);
      border-right: 1px solid transparent;
      line-height: 31px;
      height: 34px;
      width: 178px;
      text-align: center;
      right: 0px;

      cursor: pointer;
      filter: brightness(1);
      &:hover {
        filter: brightness(1.2);
      }
      & span {
        margin: 0 4px;
      }
    }

    & .ms-Dropdown {
      opacity: 0.6;
      transition: opacity 0.2s;
      &:not(.is-disabled):hover {
        opacity: 0.9;
      }
      &.is-disabled {
        opacity: 0.2;
      }

      .ms-Dropdown-caretDownWrapper {
        transition: transform 0.2s;
        font-size: 9px;
        color: #ffffff;
      }
      &:not(.is-open) .ms-Dropdown-caretDownWrapper {
        transform: rotate(0deg);
        margin-top: 1px;
      }
      &.is-open .ms-Dropdown-caretDownWrapper {
        transform: rotate(-180deg);
        margin-top: 0px;
      }
    }

    & .ms-Dropdown-title {
      border-color: transparent;
      background: transparent;
      text-align: right;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: -0.2px;
      width: 67%;
      margin: auto 0 auto auto;
    }

    & .dropdown-title {
      &:not(.has-subtitle) {
        transform: translateY(-29px);
        font-size: 17px;
      }
      &.has-subtitle {
        transform: translateY(-35px);
        font-size: 16px;
      }
      position: absolute;
      font-weight: 400;
      color: #e3e3e3;
      pointer-events: none;
      &.disabled {
        opacity: 0.2;
      }
    }
    & .dropdown-subtitle {
      position: absolute;
      font-size: 10px;
      transform: translateY(-14px);
      opacity: 0.7;
      font-weight: 400;
      letter-spacing: -0.1px;
      pointer-events: none;
    }

    & .ms-Dropdown-container {
      outline: none;
      border-color: transparent;
    }
    & .ms-Dropdown:focus::after {
      border: none;
      border-color: transparent;
    }

    & .dropdown-container {
      width: 48%;
      height: 3em;
      zoom: 0.9;
      @media (max-width: 900px) {
        zoom: 0.75;
      }
    }
  }

  #calendar-container {
    //position: absolute;
    //bottom: -4px;
    margin: auto;
    margin-top: 3px;
    //width: inherit;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    order: 1;
    //background: #242526;
    //border: 1px solid rgba(255, 255, 255, 0.14);
    //box-shadow: 0px 1px 10px 0px rgba(6, 6, 6, 0.2);
    width: 60%;
    //max-width: 380px;
    justify-content: center;

    .ms-DatePicker {
      z-index: 2;
      background: #242526;
      border: 1px solid rgba(255, 255, 255, 0.14);
      box-shadow: 0px 1px 10px 0px rgba(6, 6, 6, 0.2);
      width: 280px;
      align-items: center;
      margin-top: 8px;
    }

    .ms-DatePicker-holder {
      height: unset;
      min-height: unset;
    }
    .ms-DatePicker-wrap {
      padding-bottom: 12px;
      min-height: unset;
    }
    .ms-DatePicker-dayPicker,
    .ms-DatePicker-monthPicker {
      min-height: unset;
    }

    .check-container {
      width: 256px;
      transform: translateY(8px);
      transform: translateY(8px);
    }
  }
}

#calendar-container button[class^="currentItemButton"] {
  color: white !important;
}

.ms-CalendarDay-dayIsToday {
  background-color: transparent !important;
  color: unset !important;
}

td[class^="dayCell"] > .ms-CalendarDay-dayIsToday {
  color: #4c4d4e !important;
  font-weight: inherit !important;
}

.filter-group {
  width: 100%;
  flex-direction: column;
  margin-bottom: 1rem;
}
