.local-filter{
	background-color: #222222;
    border: 1px solid #272829;
    position: relative;
    display: flex;
    top: -27px;
    width: max(160px, 20%);
    height: 44px;
    padding: 0 22px;
    line-height: 44px;
    border-radius: 6px;
	font-size: 14px;
    color: #ffffff;
    letter-spacing: -0.2px;
    margin: auto 0 -42px auto;
	@media (max-width: 900px) {	// isMobile
		margin: 12px auto -20px auto;
	}

	& ~ .chartjs-container {
		margin-top: 20px;
	}

	.toolbar{
		display: flex;
		width: 100%;
		justify-content: space-between;
	}
	
	.title{
		font-weight: 400;
		color: #d8d8d8;
	}
	.selected{
		font-weight: 200;
		color: #949595;
		
	}


	& .ms-Dropdown{
		opacity: 0.6;
		transition: opacity 0.2s;
		&:not(.is-disabled):hover{
			opacity: 0.9;
		}
		&.is-disabled{
			opacity: 0.2;
		}

		.ms-Dropdown-caretDownWrapper{
			transition: transform 0.2s;
			font-size: 9px;
			color: #ffffff;
		}
		&:not(.is-open) .ms-Dropdown-caretDownWrapper{
			transform: rotate(0deg);
			margin-top: 1px;
		}
		&.is-open .ms-Dropdown-caretDownWrapper{
			transform: rotate(-180deg);
			margin-top: 0px;
		}
	}

	& .ms-Dropdown-title{
		border-color: transparent;
		background: transparent;
		text-align: right;
		font-size: 13px;
		color: #ffffff;
		letter-spacing: -0.2px;
	}

	& .dropdown-title{
		&:not(.has-subtitle){
			transform: translateY(-1px);
			font-size: 15px;
		}
		&.has-subtitle{
			transform: translateY(-35px);
			font-size: 16px;
		}
		position: absolute;
		font-weight: 400;
		color: #e3e3e3;
		pointer-events: none;
		&.disabled{
			opacity: 0.2;
		}
	}
	& .dropdown-subtitle{
		position: absolute;
		font-size: 10px;
		transform: translateY(-14px);
		opacity: 0.7;
		font-weight: 400;
		letter-spacing: -0.1px;
		pointer-events: none;
	}

	& .ms-Dropdown-container{
		outline: none;
		width: 100%;
		& div{
			& span{
				height: 40px;
				line-height: 40px;
			}
			&:hover{
				//border-color: transparent !important;
				& .ms-Dropdown-title{
					border-color: transparent !important;
				}
			}
		}
	}
	& .ms-Dropdown:focus::after{
		border: none;
	}

	& .dropdown-container{
		width: 100%;
		flex-grow: 1;
		height: 3em;
	}
}
/*
background: linear-gradient(180deg, #252727, rgba(49, 49, 49, 0.95));
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    color: #dedede;
    font-size: 12px;
    font-weight: 400;
    border-radius: 3px 0 0 3px;
    border: 1px solid rgba(255, 255, 255, 0.14);
    border-right: 1px solid transparent;
    line-height: 31px;
    height: 34px;
    width: 178px;
    text-align: center;
    right: 0px;
    cursor: pointer;
    filter: brightness(1);
	*/